@tailwind base;
@tailwind components;
@tailwind utilities;

.picker-down-arrow-bg {
  appearance: none;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-position: top 50% right 4px;
  background-size: 1.2em;
}

.productfruits--container {
  @apply font-sora-regular;
}

.outline-none {
  outline: none;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  @apply border-b-secondary-400;
}

@layer components {
  .input-base {
    @apply text-lg py-2 px-4 text-black rounded-xs border border-gray-500;
  }

  .label-base {
    @apply text-black text-xs py-1;
  }

  .text-enabled {
    @apply text-gray-800;
  }

  .text-enabled-inverted {
    @apply text-gray-50;
  }

  .text-disabled {
    @apply text-gray-300;
  }

  .text-disabled-inverted {
    @apply text-gray-700;
  }

  .text-subdued {
    @apply text-gray-600;
  }

  .text-subdued-inverted {
    @apply text-gray-300;
  }

  .text-link {
    @apply text-secondary-500;
  }

  .text-link-hover {
    @apply text-secondary-600;
  }

  .text-success {
    @apply text-success-500;
  }

  .text-success-inverted {
    @apply text-success-50;
  }

  .text-warning-inverted {
    @apply text-warning-50;
  }

  .text-danger {
    @apply text-danger-700;
  }

  .text-danger-inverted {
    @apply text-danger-50;
  }

  .text-accent-primary {
    @apply text-primary-500;
  }

  .text-accent-secondary {
    @apply text-secondary-500;
  }

  .text-accent-algea {
    @apply text-algea-400;
  }
}
